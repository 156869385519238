import logo from "../src/assets/images/brands/logo-lg.png";

export const clientName = "GGCC Markets";
export const developedBy = "GGCC Markets";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "GGCC Markets";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;